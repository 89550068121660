import { axios, API_ROUTES } from "@/config";

export default {
  async getAll(params) {
    let result = await axios.get(`${API_ROUTES.sitemap.get}`, {
      params: params,
    });

    return result;
  },
  async find(path, params) {
    let result = await axios.get(`${API_ROUTES.sitemap.get}/${path}`, {
      params: params,
    });

    return result;
  },
};
// <!-- https://{{host}}/api/v1/sitemap?dev0={{pass}}

// https://{{host}}/api/v1/sitemap/paginas?dev0={{pass}}

// https://{{host}}/api/v1/sitemap/tipo-de-fincas?dev0={{pass}}

// https://{{host}}/api/v1/sitemap/fincas?dev0={{pass}}

// https://{{host}}/api/v1/sitemap/fincas/page-1?dev0={{pass}}

// https://{{host}}/api/v1/sitemap/fincas/page-2?dev0={{pass}} -->
